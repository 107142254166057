import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { FacilitiesSelectors } from '../../store';

export const hasFeatureFlag = (...flags: string[]) => {
  return () => {
    const store = inject(Store);

    const featureFlags = store.selectSignal(
      FacilitiesSelectors.selectFeatureFlags
    );

    return flags.some((flag) => featureFlags()[flag]);
  };
};
